<template style="text-align:right;">
	<div>
		<TrendChart :fundId="props.fundId" ref="chart"></TrendChart>
	</div>
	<div>
		<div class="span">
			<div style="width: 100px;text-align: right; display: inline-block">总资产：</div> {{ getData.fundInfo.totalAssets }}
		</div>
		<div class="span">
			<div style="width: 100px;text-align: right; display: inline-block">基金份额：</div> {{ getData.fundInfo.share }}
		</div>
		<div class="span">
			<div style="width: 100px;text-align: right; display: inline-block">基金净值：</div> {{ getData.fundInfo.netAssets }}
		</div>
		<div class="span">
			<div style="width: 100px;text-align: right; display: inline-block">基金单位净值：</div> {{ getData.fundInfo.nuitNetAssets
			}}
		</div>
	</div>
	<div style="line-height: 50px;">
		<span class="p" style="margin-bottom: 20px;">视频附件地址：</span>
		<el-input type="textarea" style="width:90%;" :rows="2" placeholder="请复制B站--视频--分享--嵌入代码内容"
			v-model="getData.videoUrl"></el-input>
	</div>
	<div>
		<span class="p" style="margin-bottom: 20px;padding-top: 20px;margin-left: 28px;">文档附件:
			<el-upload class="upload-demo" :http-request="uploadDocFile" :show-file-list="false" :file-list="docFilesUrls">
				<el-button size="mini" class="upload-button">点击上传</el-button>
			</el-upload>
		</span>
		<template v-if="getData.showDocFile">
			<div v-for="item in getData.docFile">
				<div style="font-size:14px; margin-top:10px;margin-left: 97px;width: 380px;">
					<div style="margin-right: 10px;border: 1px solid rgba(36, 136, 251, 0.16);border-radius: 3px;"
						@click="dw(item.fileUrl, item.fileName)">
						<el-tooltip class="item" effect="dark" :content="item.fileName" placement="bottom">
							<span
								style="display: inline-block;width: 310px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;padding: 5px;position: relative;top: 2px;">{{
									item.fileName }}</span>
						</el-tooltip>

						<el-icon class="fileNameText">
							<Download />
						</el-icon>
					</div>
				</div>
			</div>
		</template>
	</div>
	<div style="line-height: 50px;margin-top: 10px;margin-left: 46px;">
		<span class="p" style="margin-bottom: 17px;">描述:</span>
		<el-form ref="ruleFormRef" :model="getData" :rules="rules">
			<el-form-item prop="textarea">
				<el-input type="textarea" style="width:93%;margin-left: 20px" :rows="2" placeholder="请简单描述"
					v-model="getData.textarea"></el-input>
			</el-form-item>
		</el-form>
	</div>
	<div style="margin-left: 100px;">
		<el-button type="primary" @click="saveInfo()" style="margin-top: 10px;width: 20%;" size="small">保存</el-button>
	</div>
	<div style="font-weight: 400 !important;">
		<p class="p" style="margin-bottom: 20px;padding-top: 20px">讨论区</p>
		<FundInvestor :fundId="props.fundId" ref="investor"></FundInvestor>
	</div>
</template>

<script setup>
import TrendChart from '@/components/TrendChart/index2.vue' //走势图
import FundInvestor from '@/views/trade-personal/components/FundInvestor/index3.vue'
import { ref, reactive, getCurrentInstance } from 'vue'
import { getFundInfo, uploadVideoOpenFundFile, updateFundInfo } from '../fundApi.js'
import { ElMessage, ElLoading } from 'element-plus';
let videoFilesUrls = [];
let docFilesUrls = [];
let getData = reactive({
	vueExample: getCurrentInstance(),
	fundInfo: {
		"totalAssets": '',
		"share": '',
		"netAssets": '',
		"nuitNetAssets": '',
	},
	docFile: [],
	showDocFile: ref(false),
	textarea: ref(''),
	videoUrl: ref(''),
})
const rules = reactive({
	textarea: [
		{ required: true, message: '请简单描述', trigger: 'change' }
	]
})
const props = defineProps({
	fundId: {
		type: Number,
	}
})
const ruleFormRef = ref(null)
const chart = ref(null);
const investor = ref(null);

function saveInfo() {
	ruleFormRef.value.validate((valid) => {
		if (valid) {
			// var formData = new FormData();
			// formData.append("id", props.fundId);
			// formData.append("info",getData.textarea);
			let prams = {
				"id": props.fundId,
				"info": getData.textarea,
				"videoUrl": getData.videoUrl
			};
			updateFundInfo(prams).then(res => {
				if (res.data.code == '200') {
					ElMessage({ type: 'success', message: '保存成功' });
				} else {
					ElMessage({ type: 'error', message: res.data.msg });
				}
			})
		}
	})

};


function uploadDocFile(params) {
	const loading = ElLoading.service({
		lock: true,
		text: '上传中',
		background: 'rgba(0, 0, 0, 0.7)',
	})
	docFilesUrls = [];
	const _file = params.file;
	var formData = new FormData();
	formData.append("file", _file);
	formData.append("fundId", props.fundId);
	formData.append("userId", JSON.parse(sessionStorage.getItem("user")).userId);
	uploadVideoOpenFundFile(formData).then(res => {
		if (res.data.code == '200') {
			let file = {
				"fileName": res.data.data.fileName,
				"fileType": res.data.data.fileType,
				"fileUrl": res.data.data.fileUrl
			};
			docFilesUrls.push(file);
			getData.docFile.push({ "fileName": res.data.data.fileName, "fileUrl": res.data.data.fileUrl });
			getData.showDocFile = true;
			ElMessage.success('上传成功')
		} else {
			ElMessage({ type: 'error', message: res.data.msg });
		}
		loading.close()
	}).catch(() => {
		loading.close();
	});
};

function dw(fileUrl, fileName) {
	let url = "/tradelab/documentSharing/download/openFunddFile"
	let params = {
		fileName: fileName,
		url: fileUrl
	}
	const { proxy } = getData.vueExample
	proxy.$DownloadAsExcel(url, params, fileName)
}

const marketQuotations = (fundId) => {
	chart.value.marketQuotations(fundId);
}
const listPl = (fundId) => {
	investor.value.listPl(fundId);
}
const getFundInfo1 = (fundId) => {
	getData.docFile = [];
	var formData = new FormData();
	formData.append("fundId", fundId);
	getFundInfo(formData).then(res => {
		getData.fundInfo = res.data.data;
		getData.showVideoFile = true;
		for (let i = 0; i < res.data.data.docFiles.length; i++) {
			let doc = {
				"fileName": res.data.data.docFiles[i].fileName,
				"fileUrl": res.data.data.docFiles[i].fileUrl
			};
			getData.docFile.push(doc);
		}
		getData.showDocFile = true;
		getData.textarea = res.data.data.text;
		getData.videoUrl = res.data.data.videoUrl;
	});
}
defineExpose({
	marketQuotations,
	listPl,
	getFundInfo1
});
</script>
<style lang="less" scoped>
.span {
	margin-top: 20px;
	font-size: 14px;
	color: rgb(107, 105, 105);
}

.p {
	font-size: 14px;
	color: rgb(107, 105, 105);
}

.fileNameText {
	// background: rgba(36, 136, 251, 0.06);
	// border: 1px solid rgba(36, 136, 251, 0.16);
	margin-left: 15px;
	font-size: 16px;
	padding: 4px;
	border-radius: 15px;
	position: relative;
	top: -6px;
	color: #f58080 !important;
}

:deep(.el-button--primary.is-plain) {
	color: rgba(36, 136, 251) !important;
	background-color: #ecf5ff !important;
	border-color: #b3d8ff !important;
}

.fileNameText:hover {
	color: #f23c3c !important;
	cursor: pointer;
}

.upload-demo {
	margin-left: 10px;
	display: inline-block;
}

.upload-button {
	background: rgba(36, 136, 251, 0.06);
	border: 1px solid rgba(36, 136, 251, 0.16);
	color: #2488FB !important;
}

.upload-button:hover {
	background-color: #2488FB !important;
	color: #fff !important;
}
:deep .el-form-item__error{
	margin-left: 20px !important;
}
</style>